// import { mapMutations, mapState } from 'vuex';

export const arrayPaginationMixin = {
  data() {
    return {
      arrayCount: 0,
      elementsPerPage: 5,
      arrayPage: 1,
    };
  },
  computed: {
    // ...mapState(['currentPage']),

    arrayLastPage() {
      return Math.ceil(this.arrayCount / this.elementsPerPage);
    },
    startIndex() {
      return this.elementsPerPage * (this.arrayPage - 1);
    },
    endIndex() {
      return this.startIndex + this.elementsPerPage;
    },
    arrayPagination() {
      return {
        currentPage: this.arrayPage,
        arrayLastPage: this.arrayLastPage,
        elementsPerPage: this.elementsPerPage,
        arrayCount: this.arrayCount,
        startIndex: this.startIndex,
        endIndex: this.endIndex,
      };
    },
  },
  methods: {
    // ...mapMutations(['setCurrentPage']),

    initArrayPagination(arrayCount = 0, elementsPerPage = 30, arrayPage = 1) {
      this.arrayCount = arrayCount;
      this.elementsPerPage = elementsPerPage;
      this.arrayPage = arrayPage;
    },
    resetArrayPagination() {
      this.arrayCount = 0;
      this.elementsPerPage = 30;
      this.arrayPage = 1;
    },
    setPaginationArrayCount(value) {
      this.arrayCount = value;
    },
    setPaginationElementsPerPage(value) {
      this.elementsPerPage = value;
    },
    setPaginationArrayPage(value) {
      this.arrayPage = value;
      // this.setCurrentPage(value)
    },
  },
};
