import { mapState, mapGetters } from 'vuex';

import { postGetTotalOverdueDebtOfTheStudent } from "../../helpers/utilsDebits";
import { postCreateBoletaReport } from "../../helpers/utilsStudent";

export default {
  props: {
    showReportModal: Boolean,
    currentStudent: Object
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      activeTab: 0,
      startMenu: false,
      endMenu: false,
      hasDebt: false,
      reportCards: [],
      reportCardsSpecial: [],
      loading: false
    };
  },
  computed: {
    ...mapState(['institutionId']),
    ...mapGetters(['currentSchoolYear']),
  },
  watch: {
    showReportModal(isOpen) {
      if (isOpen && this.currentStudent && this.currentStudent.id) {
        this.validateDebt();
        if (this.currentSchoolYear && this.currentSchoolYear.fecha_inicio && this.currentSchoolYear.fecha_fin) {
          this.startDate = this.currentSchoolYear.fecha_inicio;
          this.endDate = this.currentSchoolYear.fecha_fin;
        } else {
          console.error("Current school year is missing or has invalid properties.");
        }
      } else {
        console.error("Modal cannot be opened. Check if isOpen is true and if currentStudent is valid.");
      }
    }    
  },

  methods: {
    async handleModalOpen(isOpen) {
      console.log("Modal opened or closed:", isOpen);
      if (isOpen && this.currentStudent && this.currentStudent.id) {
        await this.validateDebt();
      }
    },

    async validateDebt() {
      console.log(this.currentStudent);
      const { ok, data } = await postGetTotalOverdueDebtOfTheStudent({
        id_alumno: this.currentStudent.id,
        fecha: '2023-02-15',
      })
      if (!ok) console.log('No se pudieron recuperar los adeudos');
      if (data > 0)
        this.hasDebt = true;
    },

    async viewReportCards() {
      this.loading = true
      try {
        if (!this.hasDebt) {
          const { ok, data } = await postCreateBoletaReport(this.currentStudent.id, this.startDate, this.endDate);
          if (!ok) {
            console.log('No se pudo recuperar el reporte del alumno');
            return;
          }

          const reportCards = [];
          const reportCardsSpecial = [];

          for (let materia in data.calificaciones_por_materia) {
            const materiaInfo = data.calificaciones_por_materia[materia];

            materiaInfo.calificaciones.forEach((calificacion) => {
              reportCards.push({
                name: materia,
                date: calificacion.fecha_evaluacion,
                calificacion: calificacion.calificacion_numerica,
                periodo: calificacion.periodo,
                promedio_final_materia: materiaInfo.promedio_final_materia
              });
            });
          }

          for (let materia in data.calificaciones_por_materias_grupos_especiales) {
            const materiaInfo = data.calificaciones_por_materias_grupos_especiales[materia];

            materiaInfo.calificaciones.forEach((calificacion) => {
              reportCardsSpecial.push({
                name: materia,
                date: calificacion.fecha_evaluacion,
                calificacion: calificacion.calificacion_numerica,
                periodo: calificacion.periodo,
                promedio_final_materia: materiaInfo.promedio_final_materia
              });
            });
          }

          this.reportCards = reportCards;
          this.reportCardsSpecial = reportCardsSpecial
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false
      }

    },

    closeModal() {
      this.$emit('actionCloseResults')
      this.reportCards = []
      this.reportCardsSpecial = []
    }

  },
};
