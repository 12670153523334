import { mapMutations, mapState, mapGetters } from 'vuex';

import {
  fetchStudentByName,
  fetchWithdrawStudent,
  reactivateStudent,
} from '../helpers/utilsStudent';

import { getItem } from '../../../api/api-methods';

import { paginationMixin } from '@/modules/shared/mixins/paginationMixin';
import { arrayPaginationMixin } from '../../shared/mixins/arrayPaginationMixin';

import KnInactiveItemsTable from '../../shared/components/KnInactiveItemsTable.vue';
import KnLocalAlert from '../../shared/components/KnLocalAlert.vue';
import KnStudentResultsViewerModal from '../components/KnStudentResultsViewerModal/KnStudentResultsViewerModal.vue';
import { StudentUtils } from '../mixins/StudentUtilsMixin';
import {
  canAdd,
  canChange,
  canDelete,
  insufficientPermissionsMessage,
} from '../../shared/helpers/permissionsUtils';
import KnTableStudent from '../components/KnTableStudent/KnTableStudent.vue';
import KnModalStudentUnenroll from '../components/KnModalStudentUnenroll/KnModalStudentUnenroll.vue';

export default {
  components: {
    KnInactiveItemsTable,
    KnLocalAlert,
    KnStudentResultsViewerModal,
    KnTableStudent,
    KnModalStudentUnenroll,
  },
  mixins: [paginationMixin, arrayPaginationMixin, StudentUtils],
  data() {
    return {
      headers: [
        { text: '', value: 'avatar' },
        { text: 'Nombre', value: 'nombre', class: 'purple--text' },
        { text: 'Grupo', value: 'grupo', class: 'purple--text' },
        {
          text: 'Nivel educativo',
          value: 'levelName',
          class: 'purple--text',
        },
        {
          text: 'Colegiatura',
          value: 'tuition',
          class: 'purple--text',
        },
        { text: 'ID', value: 'id', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      items: [],
      inactiveItems: [],
      students: [],
      groups: [],
      openAssignGroupDialog: false,
      studentToAssign: {
        groupId: null,
      },
      showInactive: false,
      studentResults: [],
      loading: false,
      expanded: [],
      showUnenrollModal: false,
      showTicketsModal: false,
      currentStudent: {},
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loadingAlert: false,
      showAlert: false,
      alertType: 'info',
      alertText: insufficientPermissionsMessage(),
      alertColor: 'warning',
      /*********************** */
      groupIdFilter: null,
      selectedGroup: null,
      groupStudents: [],
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'search', 'currentPage']),
    ...mapGetters(['tutorId', 'hasAdminPermission']),
  },
  watch: {
    institutionId: {
      async handler() {
        this.setPaginationLimit(30);
        if (this.currentPage) this.setPaginationPage(this.currentPage);
        await this.fetchData(true);
      },
    },
  },
  async created() {
    // console.log('this.$route.params.groupId', this.$route.params.groupId);
    this.groupIdFilter = this.$route.params.groupId;
    this.setPaginationLimit(30);
    if (this.currentPage) this.setPaginationPage(this.currentPage);
    await this.fetchData(true);
    this.setIsLogin(false);
  },

  methods: {
    ...mapMutations(['setIsLogin', 'setCurrentPage']),
    canAdd: canAdd,
    canDelete: canDelete,
    async fetchData(visible) {
      try {
        // const [groups] = await Promise.all([
        //   getItem(
        //     `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}`
        //   ),
        //   this.getStudents(visible, this.search),
        // ]);
        if (this.groupIdFilter) {
          // console.log('Se debe obtener la información del grupo');

          const groupResponse = await getItem(
            `/app-personas/grupo/${this.groupIdFilter}`
          );
          // console.log('groupResponse', groupResponse);

          if (!groupResponse.e) {
            this.selectedGroup = groupResponse;
            this.groupStudents = this.selectedGroup.alumnos;
            this.setPaginationArrayCount(this.groupStudents.length);
            this.setPaginationElementsPerPage(30);
            // console.log('groupStudents', this.groupStudents);

            await this.getStudents(visible, this.search);
            // console.log('arrayPagination', this.arrayPagination);
          }
        } else {
          await this.getStudents(visible, this.search);
          this.showInactive = false;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    async getStudents(visible, search) {
      try {
        this.loading = true;
        let count;

        if (search) {
          const { data } = await fetchStudentByName(
            this.search,
            this.pagination,
            true,
            this.institutionId,
            this.groupIdFilter
          );

          if (data && data.results) {
            this.students = data.results;
            count = data.count;

            await this.processStudents();
          } else {
            console.warn('No se encontraron resultados para la búsqueda.');
            this.students = [];
            this.items = [];
          }
        } else {
          if (this.groupIdFilter) {
            this.setPaginatedGroupStudents(this.arrayPagination.currentPage);
          } else {
            const limitFilter =
              this.pagination.limit !== null
                ? `&limit=${this.pagination.limit}`
                : '';
            const offsetFilter =
              this.pagination.offset !== 0
                ? `&offset=${this.pagination.offset}`
                : '';
            const tutors = this.tutorId ? `&tutores=${this.tutorId}` : '';
            const url = `/app-personas/filters/alumno?institucion_educativa=${this.institutionId}&estatus_sistema=${visible}${offsetFilter}${limitFilter}${tutors}`;

            const responseData = await getItem(url);
            count = responseData.count;
            this.students = responseData.results;
          }
          await this.processStudents();
          this.setPaginationCount(count);
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        this.loading = false;
      }
    },

    async processStudents() {
      try {
        const processedStudents = await Promise.all(
          this.students.map(async (stu) => {
            return await this.studentConstructor(
              stu.id,
              stu,
              this.institutionId
            );
          })
        );
        // console.log('processedStudents', processedStudents);

        this.items = processedStudents;
      } catch (error) {
        console.error('Error procesando estudiantes:', error);
      }
    },

    setPaginatedGroupStudents() {
      this.students = this.groupStudents.slice(
        this.arrayPagination.startIndex,
        this.arrayPagination.endIndex
      );
      // console.log('setPaginatedGroupStudents', this.students);
    },

    async changePageStudents(page = 1) {
      this.setPaginationPage(page);
      this.setCurrentPage(page);
      if (this.groupIdFilter) {
        this.setPaginationArrayPage(page);
      }
      await this.getStudents(!this.showInactive, this.search);
    },

    async changePageResults(page = 1) {
      this.setPaginationPage(page);
      await this.getResults();
    },

    openModalResults(value) {
      const student = this.items.find((element) => element.id === value);
      this.currentStudent = student;
      this.showTicketsModal = true;
    },
    closeModalReport() {
      this.showTicketsModal = false;
    },

    searchResults() {
      console.log('searching');
    },

    buttomActionText() {
      return this.showInactive
        ? 'Ver alumnos activos'
        : 'Ver alumnos inactivos';
    },

    tableTitle() {
      return this.showInactive
        ? 'Alumnos inactivos'
        : `Alumnos ${
            this.groupIdFilter && this.selectedGroup
              ? this.selectedGroup.nombre_grupo
              : ''
          }`;
    },

    openAssignGroup(value) {
      this.openAssignGroupDialog = true;
      // console.log('Se regresa el origen y el id del item?', value);
      this.studentToAssign = { ...value };
    },
    async assignGroupToStudent(/*groupObj*/) {
      // console.log('Se va  asignar grupo a Alumno', groupObj);
      // const responseAssign = await this.postObj('/personas/agregar-alumno-grupo', {
      //   id_alumno: this.studentToAssign.itemId,
      //   id_grupo: this.groupObj.groupId
      // })
    },
    async actionInactive(value) {
      try {
        this.items = [];
        this.resetPagination();
        this.setPaginationLimit(30);
        await this.getStudents(!value);
        this.showInactive = value;
      } catch (error) {
        console.error('Error during actionInactive:', error);
      }
    },

    async action2(studentId) {
      if (this.showInactive) await this.enableStudent(studentId);
      else await this.disableStudent(studentId);
    },

    async disableStudent(studentId) {
      try {
        if (canDelete('alumno')) {
          // console.log(studentId);
          const student = this.items.find(
            (element) => element.id === studentId
          );
          this.currentStudent = student;
          this.showUnenrollModal = true;
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar desactivar alumno', error);
      }
    },
    closeModal() {
      this.showUnenrollModal = false;
    },

    async enableStudent(studentId) {
      try {
        if (canChange('alumno')) {
          const { ok } = await reactivateStudent(studentId);
          if (ok) {
            const student = this.items.find(
              (element) => element.id === studentId
            );
            const formattedDate = new Date().toISOString().split('T')[0];
            await fetchWithdrawStudent({
              alumno: studentId,
              fecha_movimiento: formattedDate,
              institucion_educativa: student.institucion_educativa.id,
              motivo: 'Alta',
              tipo_movimiento: 'baja',
            });
          }
          this.items = [];
          await this.getStudents(!this.showInactive, this.search);
        } else {
          this.showAlert = true;
        }
      } catch (error) {
        console.error('Error al intentar reactivar un alumno', error);
      }
    },
  },
};
